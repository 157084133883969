import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderAttentionService } from 'src/app/services/order-attention.service';
import { OrderFirebaseService } from 'src/app/services/order-firebase.service';
import { Order } from 'src/app/withdraws/models/models';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pending-order-list',
  templateUrl: './pending-order-list.component.html',
  styleUrls: ['./pending-order-list.component.scss'],
  providers: [ OrderAttentionService ]
})
export class PendingOrderListComponent implements OnInit, OnDestroy {

  qrData = environment.WebPlatform;
  webPlatform = environment.WebPlatform;

  companyCode: string;
  storeCode: string;
  displayedColumns: string[] = [ 'position', 'customer', 'service' ];

  orders: Order[] = [];
  orderAttentionsProgress: Order[] = [];
  orderAttentionsPending: Order[] = []; 

  newOrders: Subscription;
  updatedOrders: Subscription;

  constructor(private orderAttentionService: OrderAttentionService, public orderFirebaseService: OrderFirebaseService) {
    this.companyCode = localStorage.getItem('companyCode');
    this.storeCode = localStorage.getItem('storeCode');
  }

  ngOnInit(): void {
    this.getOrdersAttention();

    this.newOrders = this.orderFirebaseService.getNewQueueManager(this.companyCode, this.storeCode).subscribe(response => {
      this.getOrdersAttention();
    });

    this.updatedOrders = this.orderFirebaseService.getUpdateQueueManager(this.companyCode, this.storeCode).subscribe(data => {
      this.getOrdersAttention();
    });

    this.qrData = `${this.webPlatform}/withdraws/mobile/${this.companyCode}/${this.storeCode}`;
  }

  ngOnDestroy(): void {
    this.newOrders.unsubscribe();
    this.updatedOrders.unsubscribe();
  }

  getOrdersAttention(): void {
    this.orderAttentionService.getOrdersAttention(this.storeCode, this.companyCode).subscribe((response: any) => {
      this.orders = this.removeDuplicates(response.data);
      this.orderAttentionsPending = [];
      this.orderAttentionsProgress = [];
      this.orders.forEach((order: Order) => {
        if (order?.status === 'progress') {
          this.orderAttentionsProgress.push(order);
          const orderAttentionsProgressNew = this.orderAttentionsProgress.filter(order => order.status !== 'cancel');
          this.orderAttentionsProgress = orderAttentionsProgressNew;
        } else if (order?.status === 'pending') {
          this.orderAttentionsPending.push(order);
          const orderAttentionsPendingNew = this.orderAttentionsPending.filter(order => order.status !== 'cancel');
          this.orderAttentionsPending = orderAttentionsPendingNew;
        }
      });
      this.recalculateOrdersBlank();
    });
  }

  recalculateOrdersBlank(): void {
    let x = 0;
    if(this.orders.length > 0){
      if(this.orders.length <= 5){
        x = 5 - this.orders.length;
      }else if (this.orders.length <= 10){
        x = 10 - this.orders.length;
      }else if (this.orders.length <= 20){
        x = 20 - this.orders.length;
      }else if (this.orders.length <= 30){
        x = 30 - this.orders.length;
      }else if (this.orders.length <= 40){
        x = 40 - this.orders.length;
      }
    }
    
    let orderBlank = {
      "orderId": "",
      "receptorDocumentNumber": "",
      "receptor": "",
      "dateDelivery": "",
      "checked": true,
      "sellCompany": "",
      "id": 0,
      "op": "",
      "username": "",
      "documentNumber": "",
      "customerName": "",
      "service": "",
      "timeArrival": "",
      "startTime": "",
      "endTime": "",
      "status": "blank",
      "customerEmail": "",
      "customerPhone": "",
      "listProduct": []
    }
    for (var i = 0; i < x; i++) {
      this.orders.push(orderBlank);
    }
  }

  removeDuplicates(orders: Order[]): Order[] {
    let response: Order[] = [];
    orders.forEach(order => {
      let index = response.findIndex(element => element.documentNumber === order.documentNumber);
      if (index >= 0) {
        if (order.status === 'progress') { response[index] = order; }
      } else {
        response.push(order);
      }
    });
    return response;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    animateOut: false,
    animateIn: false,
    autoplay: true,
    autoplayTimeout: 10000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }
}